/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Hero from 'components/hero';
import H3 from 'components/h3';
import Partners from 'components/partners';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function AboutUs () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.about_us.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.about_us.meta_desc' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.home.section.about.title' })}
					subtitle={intl.formatMessage({ id: 'page.home.section.about.subtitle' })}
				/>
			</Section>
		<Section type="no-padding">
				<Text>
					<H3>{intl.formatMessage({ id: 'page.about_us.section.partners.title' })}</H3>
					<Partners
						partners={[
							{
								name: 'CentralApp',
								description: intl.formatMessage({ id: 'page.home.partners.centralapp.desc' }),
								logo: '/img/centralapp.png',
								url: 'https://centralapp.com'
							},
							{
								name: 'Oyé Oyé',
								description: intl.formatMessage({ id: 'page.home.partners.oyeoye.desc' }),
								logo: '/img/oyeoye.png',
								url: 'https://oye-oye.net/'
							},
							{
								name: 'Petit Futé',
								description: intl.formatMessage({ id: 'page.home.partners.petitfute.desc' }),
								logo: '/img/petitfute.png',
								url: 'http://petitfute.be'
							},
							{
								name: 'Deliveroo',
								description: intl.formatMessage({ id: 'page.home.partners.deliveroo.desc' }),
								logo: '/img/deliveroo.png',
								url: 'http://deliveroo.be'
							},
							{
								name: 'Choco',
								description: intl.formatMessage({ id: 'page.home.partners.choco.desc' }),
								logo: '/img/choco.png',
								url: 'https://choco.com/fr-2/fr'
							},
							{
								name: 'TheFork',
								description: intl.formatMessage({ id: 'page.home.partners.thefork.desc' }),
								logo: '/img/thefork.png',
								url: 'https://thefork.be'
							},
							{
								name: 'Bancontact Payconiq Company',
								description: intl.formatMessage({ id: 'page.home.partners.payconiq.desc' }),
								logo: '/img/payconiqbybancontact.png',
								url: 'https://www.bancontactpayconiq.com/fr'
							}
		
						]}
					/>

					<p style={{ textAlign: 'center' }}>
						<FormattedMessage
							id="page.home.section.about.illustrations"
							values={{
								a: (...chunks) => (
									<a target="_blank" rel="noopener noreferrer" href="https://instagram.com/alixdebroux">
										{chunks}
									</a>
								)
							}}
						/>
					</p>
				</Text>
			</Section>
		</React.Fragment>
	);
}