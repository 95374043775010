/*
 *
 */
import React from 'react';
import styled from 'styled-components';

const Heading = styled.h3`
	margin:1em 0;

	&:first-child {
		margin-top:0;
	}

	&:last-child {
		margin-bottom:0;
	}

	font-size:2.2em;
	font-weight:500;
	text-align:center;

	@media (${props => props.theme.media.tablet}) {
		font-size:1.8em;
		text-align:left;
	}
`;

/*
 *
 */
export default function H3 ({ children }) {

	return (
		<Heading>
			{children}
		</Heading>
	);
}
